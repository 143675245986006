.redeemable-items {
    background: #393744;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0px 4px 10px rgba(126, 126, 126, 0.1);
    text-align: center;
    margin: 1px auto;
}

.redeem-title {
    color: #ffffff;
    font-size: 12px;
    margin-bottom: 5px;
}

.redeem-points {
    font-size: 14px;
    color: #F07EAA;
    margin-bottom: 5px;
}

.reward-list-container {
    overflow-x: auto; /* Enables horizontal scrolling */
    white-space: nowrap;
    padding-bottom: 5px;
    width: 100%;
}

.reward-list {
    display: flex;
    gap: 15px;
    height: 112px;
    overflow-x: auto;
    padding: 5px;
    min-width: max-content; /* Ensures it expands horizontally */
}

/* Individual reward card */
.reward-card {
    background: white;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    width: 90vw;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #F07EAA;
}

/* Smooth scrolling effect */
.reward-list-container::-webkit-scrollbar {
    height: 8px;
}

.reward-list-container::-webkit-scrollbar-thumb {
    background: #F07EAA;
    border-radius: 5px;
}

.reward-list-container::-webkit-scrollbar-track {
    background: #ddd;
}



.reward-info h4 {
    margin: 0;
    color: #333;
}

.reward-points {
    font-size: 0.9rem;
    color: #777;
}

.redeem-btn {
    background: #F07EAA;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: 0.3s;
}

.redeem-btn:hover {
    background: #F07EAA;
}

.redeem-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.no-rewards {
    color: #888;
    font-size: 1rem;
    margin-top: 20px;
}
